export default function Header() {
  return (
    <header className="text-white bg-custom-red md:flex md:flex-col md:items-center md:gap-6 md:pt-4">
      <article className="bg-red-700 p-5 py-4 md:bg-custom-darkRed w-full max-w-3xl md:p-8 rounded-md">
        <h2 className="font-bold text-4xl md:text-5xl text-center">
          Saúde Masculina
        </h2>
      </article>

      <div className="bg-custom-darkRed w-full relative">
        <div className="marquee-w max-w-5xl p-6 py-4 m-auto font-bold text-sm md:text-lg md:py-8">
          <div className="marquee">
            <p className="header-text-space uppercase">
              Atualizações diárias sobre bem-estar e saúde masculina -
              Atualizações diárias sobre bem-estar e saúde masculina -
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
          </div>
          <div className="marquee marquee2">
            <p className="header-text-space uppercase">
              Atualizações diárias sobre bem-estar e saúde masculina -
              Atualizações diárias sobre bem-estar e saúde masculina -
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
          </div>
        </div>
      </div>
    </header>
  );
}
