import { useEffect } from "react";

export default function Video() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://scripts.converteai.net/95571ba8-98d1-4a43-9477-8adefd091c5e/players/6756309d53f16bf594dbb2e6/player.js";
    script.async = true;
    script.id = "scr_6756309d53f16bf594dbb2e6";
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <article className="w-full mt-6">
      <div
        id="vid_6756309d53f16bf594dbb2e6"
        style={{
          position: "relative",
          width: "100%",
          padding: "56.25% 0 0",
        }}
      >
        <img
          id="thumb_6756309d53f16bf594dbb2e6"
          src="https://images.converteai.net/95571ba8-98d1-4a43-9477-8adefd091c5e/players/6756309d53f16bf594dbb2e6/thumbnail.jpg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            display: "block",
          }}
          alt="thumbnail"
        />
        <div
          id="backdrop_6756309d53f16bf594dbb2e6"
          style={{
            WebkitBackdropFilter: "blur(5px)",
            backdropFilter: "blur(5px)",
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100%",
          }}
        ></div>
      </div>
    </article>
  );
}
